import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const EditAsset = React.lazy(() => import('./views/Pages/EditAsset/EditAsset.js'));
const Events = React.lazy(() => import('./views/Pages/Events/Events.js'));
const EditEvent = React.lazy(() => import('./views/Pages/EditEvent/EditEvent.js'));
const Products = React.lazy(() => import('./views/Pages/Products/Products.js'));
const EditProduct = React.lazy(() => import('./views/Pages/EditProduct/EditProduct.js'));
const Categories = React.lazy(() => import('./views/Pages/Categories/Categories.js'));
const EditCategory = React.lazy(() => import('./views/Pages/EditCategory/EditCategory.js'));


let userSession = null;
userSession = JSON.parse(localStorage.getItem('userData'));
console.log('userSession', userSession);
const ProtectedRoute = ({ component: Comp, path, ...rest }) => {
  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        return userSession && userSession !== null ? (
          <Comp {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login"
            }}
          />
        );
      }}
    />
  );
};

class App extends Component {
  render() {
    return (
      <React.Suspense fallback={loading()}>
        <Router>
          <Switch>
            <ProtectedRoute path="/dashboard" name="Dashboard" component={DefaultLayout} />
            <ProtectedRoute path="/edit-asset" name="EditAsset" component={EditAsset} />
            <ProtectedRoute path="/events" name="Events" component={Events} />
            <ProtectedRoute path="/edit-event" name="EditEvent" component={EditEvent} />
            <ProtectedRoute path="/products" name="Products" component={Products} />
            <ProtectedRoute path="/edit-product" name="EditProduct" component={EditProduct} />
            <ProtectedRoute path="/categories" name="Categories" component={Categories} />
            <ProtectedRoute path="/edit-category" name="EditCategory" component={EditCategory} />

            <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
            <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>} />

          </Switch>
        </Router>
      </React.Suspense>
    );
  }
}

export default App;
